import React from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';

const HealthSafetyPage = ({ data: { page } }) => {
  const { modules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />

      <div className='bg-[#E5F6FB] relative'>
        {modules && <ModuleZone {...modules} />}
      </div>
      <ExploreMenu />
    </Layout>
  );
};

export default HealthSafetyPage;

export const pageQuery = graphql`
  query healthSafetyPageQuery {
    page: sanityHealthSafetyPage {
      seo {
        ...SEO
      }
      title
      modules {
        ...HealthSafetyModules
      }
    }
  }
`;
